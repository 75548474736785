// import instance from './request';
import axios from "@/utils/request";
// 检测手机号是否可以登录 /anyTenant/checkMobile

// 获取当前用户信息接口 /anyone/findTenantList
export const anyoneFindTenantList = (params) => {
  return axios({
    url: `/anyone/findTenantList`,
    method: "get",
    params,
  });
};
// 切换企业接口 /anyone/switchTenantAndOrg
export const anyoneSwitchTenantAndOrg = (params) => {
  return axios({
    url: `/anyone/switchTenantAndOrg`,
    method: "put",
    params,
  });
};
// 查询我的未读消息数量
export const extendNoticeAnyoneMyNoticeCount = (params) => {
  return axios({
    // url: `/extendNotice/anyone/myNoticeCount`,
    // method: 'post',
    url: `/examine/msg/un/examineCount`,
    method: "get",
    params,
  });
};
// 删除站内信接口
export const extendNoticeAnyone = (params) => {
  return axios({
    url: `/extendNotice/anyone`,
    method: "delete",
    params,
  });
};
