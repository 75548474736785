// 设置接口变量

// '//121.41.66.8/api'  外包接口地址

// '//121.41.168.20:29760' 公司接口地址

// const HTTP_API_URL = '//121.41.66.8/api'
// const HTTP_API_URL = '//121.41.168.20:29760'
const HTTP_API_URL = '//121.41.168.20:48080' //新组织架构接口
// const HTTP_API_URL = '/' //新组织架构接口
// 代理记得改
export default HTTP_API_URL