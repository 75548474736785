export const EDeviceTypeName = {
  Aircraft: 0,
  Gateway: 2,
  Dock: 3,
};

export const EBizCode = {
  GatewayOsd: "gateway_osd",
  DeviceOsd: "device_osd",
  DockOsd: "dock_osd",
  HeqCh4: "heq_ch4",
  MapElementCreate: "map_element_create",
  MapElementUpdate: "map_element_update",
  MapElementDelete: "map_element_delete",
  DeviceOnline: "device_online",
  DeviceOffline: "device_offline",
  DeviceHms: "device_hms",

  // 机场任务
  FlightTaskProgress: "flighttask_progress", // 机场任务执行进度
  FlightTaskMediaProgress: "file_upload_callback", // 机场任务媒体上传进度
  FlightTaskMediaHighestPriority: "highest_priority_upload_flighttask_media", // 机场任务媒体优先级上报

  // 设备指令
  DeviceReboot: "device_reboot", // 机场重启
  DroneOpen: "drone_open", // 飞行器开机
  DroneClose: "drone_close", // 飞行器关机
  DeviceFormat: "device_format", // 机场数据格式化
  DroneFormat: "drone_format", // 飞行器数据格式化
  CoverOpen: "cover_open", // 打开舱盖
  CoverClose: "cover_close", // 关闭舱盖
  PutterOpen: "putter_open", // 推杆展开
  PutterClose: "putter_close", // 推杆闭合
  ChargeOpen: "charge_open", // 打开充电
  ChargeClose: "charge_close", // 关闭充电
  SdrWorkmodeSwitch: "sdr_workmode_switch", // 增强图传开关

  // 设备升级
  DeviceUpgrade: "ota_progress", // 设备升级

  // 设备日志
  DeviceLogUploadProgress: "fileupload_progress", // 设备日志上传

  // 飞行指令消息
  ControlSourceChange: "control_source_change", // 控制权更新
  FlyToPointProgress: "fly_to_point_progress", // 飞向目标点
  TakeoffToPointProgress: "takeoff_to_point_progress", // 一键起飞
  JoystickInvalidNotify: "joystick_invalid_notify", // 设备端退出drc模式
  DrcStatusNotify: "drc_status_notify", // 飞行控制模式状态

  // 任务状态 
  FlighttaskProgress:'flighttask_progress'
};

export const EDockModeCode = [
  "空闲中",
  "现场调试",
  "远程调试",
  "固件升级中",
  "作业中",
];

export const EGear = ["A", "P", "NAV", "FPV", "FARM", "S", "F", "M", "G", "T"];

export const EModeCode = [
  "待机", //0
  "起飞准备", //1
  "起飞准备完毕", //2
  "手动飞行", //3
  "自动起飞", //4
  "航线飞行", //5
  "全景拍照", //6
  "智能跟随", //7
  "ADS-B 躲避", //8
  "自动返航", //9
  "自动降落", //10
  "强制降落", //11
  "三桨叶降落", //12
  "升级中", //13
  "设备未连接", //14
  "APAS", //15
  "虚拟摇杆模式", //16
  "指令飞行", //17
  "空中 RTK 收敛模式"
];

export const controlFlying = [3, 4, 5, 7, 16, 17]

export const isDroneFlying = [3, 4, 5, 6, 7, 8, 16, 17];
export const realTimeRendering = [3, 4, 5, 7, 9, 10, 16, 17];
export const takeOffProcess = [1, 2, 4, 17];
export const isReturnHomeCancel = [9, 10, 11, 12]

export const EHmsLevel = {
  NOTICE: 0,
  CAUTION: 1,
  WARN: 2,
};

export const Calibration = ["未标定", "已标定"];

export const AirConditionerState = [
  "空闲模式",
  "制冷模式",
  "制热模式",
  "除湿模式",
  "制冷退出模式",
  "制热退出模式",
  "除湿退出模式",
  "制冷准备模式",
  "制热准备模式",
  "除湿准备模式",
];

export const AirConditionerEnum = [
  "空闲中",
  "制冷中",
  "制热中",
  "除湿中",
  "制冷退出中",
  "制热退出中",
  "除湿退出中",
  "制冷准备中",
  "制热准备中",
  "除湿准备中",
];

export const AirConditionerLoadingEnum = [4, 5, 6, 7, 8, 9];

export const DeviceCmd = {
  // 简单指令
  DebugModeOpen: "debug_mode_open", // 调试模式开启
  DebugModeClose: "debug_mode_close", // 调试模式关闭
  SupplementLightOpen: "supplement_light_open", // 打开补光灯
  SupplementLightClose: "supplement_light_close", // 关闭补光灯
  ReturnHome: "return_home", // 一键返航
  ReturnHomeCancel: "return_home_cancel", // 取消返航
  // 复杂指令
  DeviceReboot: "device_reboot", // 机场重启
  DroneOpen: "drone_open", // 飞行器开机
  DroneClose: "drone_close", // 飞行器关机
  // DeviceCheck = 'device_check', // 一键排障（一键起飞自检）
  DeviceFormat: "device_format", // 机场数据格式化
  DroneFormat: "drone_format", // 飞行器数据格式化
  CoverOpen: "cover_open", // 打开舱盖
  CoverClose: "cover_close", // 关闭舱盖
  PutterOpen: "putter_open", // 推杆展开
  PutterClose: "putter_close", // 推杆闭合
  ChargeOpen: "charge_open", // 打开充电
  ChargeClose: "charge_close", // 关闭充电
  AlarmStateSwitch: "alarm_state_switch", // 机场声光报警
  BatteryStoreModeSwitch: "battery_store_mode_switch", // 电池保养模式
  DroneBatteryModeSwitch: "battery_maintenance_switch", // 飞行器电池保养
  SdrWorkModeSwitch: "sdr_workmode_switch", // 增强图传
  AirConditionerModeSwitch: "air_conditioner_mode_switch", // 空调调试
};

// 电池保养
export const BatteryMaintenanceState = {
  0: "无需保养",
  1: "待保养",
  2: "正在保养",
};
export const BatteryMaintenanceAction = { 0: "保养", 1: "保养", 2: "关闭" };
export const BatteryMaintenanceActionCode = { 0: 1, 1: 1, 2: 0 };

export const AudibleAlarmEnum = {
  0: "未开启",
  1: "已开启",
};
export const AudibleAlarmActionEnum = {
  0: "开启",
  1: "关闭",
};
export const AudibleAlarmActionCodeEnum = {
  0: 1,
  1: 0,
};

export const noDebugCmdList = [
  {
    label: "一键返航",
    status: "--",
    operateText: "返航",
    cmdKey: DeviceCmd.ReturnHome,
    func: "returnHome",
    loading: false,
  },
  // {
  //   label: '取消返航',
  //   status: '--',
  //   operateText: '取消返航',
  //   cmdKey: DeviceCmd.ReturnHomeCancel,
  //   func: 'returnHomeCancel',
  //   loading: false,
  // }
];

// 舱盖状态
export const CoverState = {
  0: "关闭",
  1: "打开",
  2: "半开",
  3: "舱盖状态异常",
};

// 舱盖状态
export const CoverAction = {
  0: "打开",
  1: "关闭",
  2: "打开",
  3: "关闭",
};
export const CoverCode = {
  0: 1,
  1: 0,
  2: 1,
  3: 0,
};
export const CoverLoading = [2];

// 推杆状态
export const PutterState = {
  0: "关闭",
  1: "打开",
  2: "半开",
  3: "推杆状态异常",
};

export const PutterAction = {
  0: "展开",
  1: "关闭",
  2: "关闭",
  3: "关闭",
};
export const PutterCode = {
  0: 1,
  1: 0,
  2: 0,
  3: 0,
};
export const PutterLoading = [];

// 图传状态
export const LinkWorkModeState = {
  0: "未开启",
  1: "已开启",
};

export const LinkWorkModeAction = {
  0: "开启",
  1: "关闭",
};

export const LinkWorkModeActionCode = {
  0: 1,
  1: 0,
};

// 飞机开机状态
export const DeviceOnlineState = {
  0: "关机",
  1: "开机",
};

// 飞机开机状态
export const DeviceOnlineAction = {
  0: "开机",
  1: "关机",
};

export const DeviceOnlineActionCode = {
  0: 1,
  1: 0,
};

// 飞行器充电状态
export const DroneChargeState = {
  0: "空闲",
  1: "充电中",
};

export const DroneChargeAction = {
  0: "充电",
  1: "断电",
};
export const DroneChargeActionCode = {
  0: 1,
  1: 0,
};

export const WorkingStatus = {
  canceled: {
    title: "任务取消或终止",
    type: "warning",
  },
  failed: {
    title: "任务失败",
    type: "error",
  },
  in_progress: {
    title: "任务正在执行中",
    type: "success",
  },
  ok: {
    title: "任务执行完成",
    type: "success",
  },
  paused: {
    title: "任务暂停",
    type: "info",
  },
  rejected: {
    title: "任务被拒绝",
    type: "error",
  },
  sent: {
    title: "任务已下发",
    type: "info",
  },
  timeout: {
    title: "请求超时",
    type: "error",
  },
};

export const BizCodeAction = {
  device_reboot: "机场重启",
  drone_open: "飞行器开机",
  drone_close: "飞行器关机",
  cover_open: "打开舱盖",
  cover_close: "关闭舱盖",
  putter_open: "推杆展开",
  putter_close: "推杆闭合",
  charge_open: "打开充电",
  charge_close: "关闭充电",
  sdr_workmode_switch: "增强图传",
};

// 一键起飞相关
export const WaylineLostControlActionInCommandFlight = {
  CONTINUE: 0,
  EXEC_LOST_ACTION: 1,
};

export const WaylineLostControlActionInCommandFlightOptions = [
  {
    value: WaylineLostControlActionInCommandFlight.CONTINUE,
    label: "继续执行航线任务",
  },
  {
    value: WaylineLostControlActionInCommandFlight.EXEC_LOST_ACTION,
    label: "退出航线任务，执行遥控器失控动作",
  },
];

export const LostControlActionInCommandFLight = {
  HOVER: 0, // 悬停
  Land: 1, // 着陆
  RETURN_HOME: 2, // 返航
};

export const LostControlActionInCommandFLightOptions = [
  {
    value: LostControlActionInCommandFLight.HOVER,
    label: "悬停",
  },
  {
    value: LostControlActionInCommandFLight.Land,
    label: "着陆",
  },
  {
    value: LostControlActionInCommandFLight.RETURN_HOME,
    label: "返航",
  },
];

export const ERthMode = {
  SMART: 0,
  SETTING: 1,
};

export const ERthModeOptions = [
  {
    value: ERthMode.SMART,
    label: "智能高度",
  },
  {
    value: ERthMode.SETTING,
    label: "设定高度",
  },
];

export const ECommanderModeLostAction = {
  CONTINUE: 0,
  EXEC_LOST_ACTION: 1,
};

export const ECommanderModeLostActionOptions = [
  {
    value: ECommanderModeLostAction.CONTINUE,
    label: "继续执行指点飞行任务",
  },
  {
    value: ECommanderModeLostAction.EXEC_LOST_ACTION,
    label: "退出指点飞行任务，执行普通失控行为",
  },
];

export const ECommanderFlightMode = {
  SMART: 0,
  SETTING: 1,
};

export const ECommanderFlightModeOptions = [
  {
    value: ECommanderFlightMode.SMART,
    label: "智能高度",
  },
  {
    value: ECommanderFlightMode.SETTING,
    label: "设定高度",
  },
];

// 控制权
export const ControlSource = {
  A: "A",
  B: "B",
};

export const ControlSourceChangeType = {
  Flight: 1,
  Payload: 2,
};

export const DRC_METHOD = {
  HEART_BEAT: "heart_beat",
  DRONE_CONTROL: "drone_control", // 飞行控制-虚拟摇杆
  DRONE_EMERGENCY_STOP: "drone_emergency_stop", // 急停
  OSD_INFO_PUSH: "osd_info_push", // 高频osd信息上报
  HSI_INFO_PUSH: "hsi_info_push", // 避障信息上报
  DELAY_TIME_INFO_PUSH: "delay_info_push", // 图传链路延时信息上报
};

export const videoQuality = [
  {
    value: 4,
    label: "超清",
  },
  {
    value: 3,
    label: "高清",
  },
  {
    value: 2,
    label: "标清",
  },
  {
    value: 1,
    label: "流畅",
  },
  {
    value: 0,
    label: "自适应",
  },
];

export const PayloadCommandsEnum = {
  CameraModeSwitch: "camera_mode_switch",
  CameraPhotoTake: "camera_photo_take",
  CameraRecordingStart: "camera_recording_start",
  CameraRecordingStop: "camera_recording_stop",
  CameraFocalLengthSet: "camera_focal_length_set",
  GimbalReset: "gimbal_reset",
  CameraAim: "camera_aim",
  CameraScreenDrag: "camera_screen_drag", // 屏幕拖动
  CameraLookAt: "camera_look_at", // look at
  CameraScreenSplit: "camera_screen_split", // 分屏设置
  PhotoStorageSet: "photo_storage_set", // 图片设置
  VideoStorageSet: "video_storage_set", // 视频设置
};

export const CameraMode = {
  Photo: 0, // 拍照
  Video: 1, // 录像
};

export const CameraTypeList = {
  normal: "正常",
  wide: "广角",
  zoom: "变焦",
  ir: "红外",
};

export const resetPTZ = ["回中", "向下", "偏航回中", "俯仰向下"];

export const ResetMode = {
  current: "正常",
  wide: "广角",
  zoom: "变焦",
  ir: "红外",
};

export const commonColor = {
  WARN: "#FF9900", // 黄色
  FAIL: "#E02020", // 红色
  WHITE: "#FFFFFF", // 白色
  NORMAL: "#19BE6B", // 绿色
  BLUE: "#2B85E4", // 蓝色
  PINK: "#F7C0BA", // 粉
  BLACK: "#000000", //黑色
};

// 固件相关
// 固件升级状态
export const DeviceFirmwareStatusEnum = {
  None: 1, // 无需升级
  ToUpgraded: 2, // 待升级
  ConsistencyUpgrade: 3, // 一致性升级
  DuringUpgrade: 4, // 升级中
};

export const DeviceFirmwareStatus = {
  [DeviceFirmwareStatusEnum.None]: "无需升级",
  [DeviceFirmwareStatusEnum.ToUpgraded]: "待升级",
  [DeviceFirmwareStatusEnum.ConsistencyUpgrade]: "一致性升级",
  [DeviceFirmwareStatusEnum.DuringUpgrade]: "升级中",
};

export const DeviceFirmwareStatusColor = {
  [DeviceFirmwareStatusEnum.None]: commonColor.BLACK,
  [DeviceFirmwareStatusEnum.ToUpgraded]: commonColor.BLUE,
  [DeviceFirmwareStatusEnum.ConsistencyUpgrade]: commonColor.WARN,
  [DeviceFirmwareStatusEnum.DuringUpgrade]: commonColor.NORMAL,
};
// 固件升级类型
export const DeviceFirmwareTypeEnum = {
  ToUpgraded: 3, // 普通升级
  ConsistencyUpgrade: 2, // 一致性升级
};

// cmd ws 消息状态
export const DeviceCmdExecuteStatus = {
  Sent: "sent", // 已下发
  InProgress: "in_progress", // 执行中
  OK: "ok", // 执行成功
  Failed: "failed", // 失败
  Canceled: "canceled", // 取消
  Timeout: "timeout", // 超时
};

export const UploadStatusEnum = [
  "待上传",
  "数据处理",
  "上传中",
  "上传完成",
  "上传失败",
  "已暂停",
  "已取消",
];

export const AlarmLevelEnum = {
  0: "通知",
  1: "注意",
  2: "告警",
};

export const AlarmLevelColorEnum = {
  0: "#70e94e",
  1: "#f19e38",
  2: "#d42e20",
};

export const Configure = {
  0: "未配置",
  1: "已配置",
};

export const BackupBatterySwitch = {
  0: "关闭",
  1: "开启",
};

export const Rainfall = {
  0: "无雨",
  1: "小雨",
  2: "中雨",
  3: "大雨",
};

export const dockMaintain = {
  17: {
    type: "default",
    name: "常规保养",
    duration: 6,
    sorties: 1500,
  },
  18: {
    type: "deep",
    name: "深度保养",
    duration: 12,
    sorties: 3000,
  },
};

export const droneMaintain = {
  1: {
    type: "basic",
    name: "基础保养",
    flight: null,
    duration: null,
    sorties: null,
  },
  2: {
    type: "default",
    name: "常规保养",
    flight: 300,
    duration: 12,
    sorties: 1000,
  },
  3: {
    type: "deep",
    name: "深度保养",
    flight: 900,
    duration: 36,
    sorties: 3000,
  },
};
