import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store'
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import 'element-plus/dist/index.css'
import './assets/iconfont/iconfont.js'
import 'element-plus/theme-chalk/dark/css-vars.css'
import '@photo-sphere-viewer/core/index.css'
import './assets/css/setDark.scss'
import 'windi.css'
import './global.scss'
// import mitt from 'mitt'
// import SlideVerify from 'vue-monoplasty-slide-verify';

// const bus = mitt()

const app = createApp(App)

app.use(store).use(router).use(ElementPlus, { locale: zhCn }).mount('#app')
// app.config.globalProperties.$bus = bus
