const state = {
    // 分屏直播数据
    displayTypeInfo: {
        domClass: 'type1',
        isPlayLen: 0,
        info: [{}, {}, {}, {}, {}, {}, {}, {}, {}],
        isPlayArr: [0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
};

const mutations = {
    SET_DOMCLASS(state, domClass) {
        state.displayTypeInfo.domClass = domClass;
    },
    SET_isPlayARR_INDEX(state, index) {
        state.displayTypeInfo.isPlayArr[index] = 0
        state.displayTypeInfo.isPlayLen = index
    },
    SET_INFO(state, infoData) {
        let len
        if (JSON.stringify(infoData) !== '{}') {
            len = state.displayTypeInfo.isPlayArr.indexOf(0)
            state.displayTypeInfo.isPlayArr[len] = 1
            state.displayTypeInfo.isPlayLen = len
        } else {
            len = state.displayTypeInfo.isPlayLen
        }
        state.displayTypeInfo.info[len] = infoData;
    },
};

const actions = {
    // 新增子模块的 actions
};

const getters = {
    // 新增子模块的 getters
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};