import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    redirect: "/login",
  },
  {
    path: "/organization",
    name: "organization",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/organ/organization.vue"
      ),
    children: [
      {
        path: "/projectList",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/projectList.vue"
          ),
          },
          {
            path: "/project",
            name: "project",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/project.vue"
              ),
            redirect:'/project/team',
            children: [
              {
                path: "/project/team",
                name: "team",
                component: () =>
                  import(
                    /* webpackChunkName: "about" */ "../views/project/team.vue"
                  ),
              },
              {
                path: "/project/airLineBase",
                name: "airLineBase",
                component: () =>
                  import(
                    /* webpackChunkName: "about" */ "../views/project/airLineBase.vue"
                  ),
              },
              {
                path: "/project/map",
                name: "map",
                component: () =>
                  import(
                    /* webpackChunkName: "about" */ "../views/project/map.vue"
                  ),
              },
              {
                path: "/project/mark",
                name: "mark",
                component: () =>
                  import(
                    /* webpackChunkName: "about" */ "../views/project/mark.vue"
                  ),
              },
              {
                path: "/project/mediaBase",
                name: "mediaBase",
                component: () =>
                  import(
                    /* webpackChunkName: "about" */ "../views/project/mediaBase.vue"
                  ),
              },
              {
                path: "/project/modelBase",
                name: "modelBase",
                component: () =>
                  import(
                    /* webpackChunkName: "about" */ "../views/project/modelBase.vue"
                  ),
              },
              {
                path: "/project/planBase",
                name: "planBase",
                component: () =>
                  import(
                    /* webpackChunkName: "about" */ "../views/project/planBase.vue"
                  ),
              },
              {
                path: "/project/logLibrary",
                name: "logLibrary",
                component: () =>
                  import(
                    /* webpackChunkName: "about" */ "../views/project/logLibrary.vue"
                  ),
              },
            ],
          },
          {
            path: "/edit",
            name: "edit",
            component: () =>
              import(/* webpackChunkName: "about" */ "../views/edit.vue"),
          },
        
      {
        path: "/planBaseCreate",
        name: "planBaseCreate",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/project/planBaseCreate.vue"
          ),
      },
     
    ],
  },
  {
    path: "/homePage",
    name: "homePage",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/homePage.vue"),
  },
  {
    path: "/user-center",
    redirect: "/user-center/no-organization",
    children: [
      {
        path: "no-organization",
        name: "no-organization",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/useCreate/noOrganization.vue"
          ),
      },
      {
        path: "my-organization",
        name: "my-organization",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/useCreate/myOrganization.vue"
          ),
      },
      {
        path: "home-page",
        name: "home-page",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/useCreate/homePage.vue"
          ),
      },
    ],
  },
  {
    path: "/organization",
    children: [
      {
        path: "member",
        name: "member",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/organization/member.vue"
          ),
      },
      {
        path: "account",
        name: "account",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/organization/account.vue"
          ),
      },
      {
        path: "roleManagement",
        name: "roleManagement",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/organization/roleManagement.vue"
          ),
      },
      {
        path: "device",
        name: "device",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/organization/device/index.vue"
          ),
      },
      {
        path: "airport",
        name: "airport",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/organization/device/airport.vue"
          ),
      },
      {
        path: "message-management",
        name: "message-management",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/organization/messageManagement/index.vue"
          ),
      },
    ],
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/login.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/register.vue"),
  },
  {
    path: "/reset",
    name: "reset",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/reset.vue"),
  },
  {
    path: "/404",
    name: "404",
    component: () => import(/* webpackChunkName: "about" */ "../views/404.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: '/404',
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const token = JSON.parse(sessionStorage.getItem('uavmsg'))?.access_token
  let nameArr = ['login', 'register', 'reset']
  if (!nameArr.includes(to.name) && !token) {
    next({ name: 'login' });
  } else {
    next();
  }
});

// router.beforeEach((to,from,next)=>{
//   // console.log(to);
//   if(to.name =='mediaBase' ||to.name =='planBase' ||to.name =='logLibrary'){
//     document.documentElement.classList.remove('dark')
//   }else{
//     document.documentElement.classList.add('dark')
//   }
//   next()
// })

export default router;
